(function () {
  'use strict';

  class MilestonesRest {
    constructor($resource, env) {
      this.$resource = $resource;
      this.env = env;
    }

    milestones() {
      return this.$resource(`${this.env.api}/milestones/module/:module_id/group/:group_id`, {module_id: '@module_id', group_id: '@group_id'});
    }

    milestone() {
      return this.$resource(`${this.env.api}/milestones/module/:module_id/:milestone_node_id`, {module_id: '@module_id', milestone_node_id: '@milestone_node_id'}, {update: {method: 'PUT'}});
    }

    pushNode() {
      return this.$resource(`${this.env.api}/milestones/module/:module_id/:milestone_id/push-node`, {module_id: '@module_id', milestone_id: '@milestone_id'}, {update: {method: 'PUT'}});
    }

    popNode() {
      return this.$resource(`${this.env.api}/milestones/module/:module_id/:milestone_id/pop-node`, {module_id: '@module_id', milestone_id: '@milestone_id'}, {update: {method: 'PUT'}});
    }
  }

  /**
   * @ngdoc service
   * @name components.service:MilestonesRest
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('MilestonesRest', MilestonesRest);
}());
